@import "styles/theme.scss";
.button {
  border-radius: $border-radius-s;

  cursor: pointer;
  
  white-space: nowrap;

  &:disabled {
    cursor: no-drop;
  }
}

.icon {
  &Download {
    width: 20px;
    height: 20px;
  }
}
