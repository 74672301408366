@import "styles/theme.scss";
:root {
  --primary-wrong-color: #{$wrong-color};
  --primary-success-color: #{$success-color};
  --primary-accent-color: #{$accent-color};
  --primary-text-color: #{$text-color};

  --default-right-color: #{$default-right-color};

  --grey-light-color: #{$grey-light-color};
  --grey-dark-color: #{$grey-dark-color};
  --grey-color: #{$grey-color};

  --toastify-font-family: #{$font-family};
  --toastify-toast-width: 470px;
  --toastify-toast-min-height: 54px;
  --toastify-color-success: #e3f9e5;
  --toastify-color-error: #fee;
  --toastify-color-info: #e9fbff;
  --toastify-text-color-info: var(--primary-text-color);
  --toastify-text-color-success: var(--primary-text-color);
  --toastify-text-color-error: var(--primary-text-color);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
}

html,
body {
  position: relative;
  height: 100%;

  font-size: var(--fontSize__h3);
  font-family: $font-family;
  font-weight: $font-weight-medium;
  font-style: normal;

  color: $text-color;
  line-height: 1.3;
}

input {
  user-select: text;
}

button,
textarea {
  border: 0;
  font-family: inherit;
  font-size: inherit;
  background-color: transparent;

  line-height: 1.3;

  &:active,
  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
  }
}

button {
  cursor: pointer;
}

input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
}

ul {
  list-style: none;
}

a {
  text-decoration: none;

  color: inherit;
}

.wrong {
  color: $wrong-color;
}

.react-datepicker {
  font-family: $font-family;
  font-weight: $font-weight-medium;

  border: 1px solid $grey-color;

  &__header {
    background-color: $grey-extra-light-color;
  }

  &__day {
    &:not([class*='disabled']) {
      color: black;
    }

    &:hover:not([class*='disabled']) {
      background-color: $blue-light-color;
    }

    &--in-selecting-range {
      background-color: rgba($accent-color, 0.4) !important;

      font-weight: $font-weight-bold;
    }

    &--range-start,
    &--range-end {
      background-color: $accent-color;

      font-weight: $font-weight-bold;
    }

    &--in-range:not([class*='range-start']):not([class*='range-end']) {
      background-color: rgba($accent-color, 0.4);

      font-weight: $font-weight-bold;
    }
  }

  &__close-icon {
    padding: 0;
  }

  &__close-icon::after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 0;
    background: none;
    background-image: url('../../public/static/images/clear-icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    padding: 10px;
  }
}

a {
  .leaflet {
    &-attribution {
      &-flag {
        display: none !important;
      }
    }
  }
}

/* stylelint-disable */

#__next {
  height: 100%;
}

/* stylelint-enable */
