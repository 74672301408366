@import "styles/theme.scss";
.card {
  position: relative;

  width: 100%;
  height: 100%;

  border-radius: $border-radius-s;

  box-shadow: $box-shadow-main;

  overflow-x: auto;

  &Size {
    &_XS {
      padding: 12px;
    }

    &_S {
      padding: 24px;
    }

    &_M {
      padding: 32px;
    }
  }
}
