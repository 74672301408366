@import "styles/theme.scss";
.header {
  display: flex;

  align-items: center;
  justify-content: space-between;

  padding: 20px;

  border-bottom: 1px solid $grey-color;
}

.list {
  display: flex;

  width: 100%;
}

.item+.item {
  margin-left: 40px;
}

.separate {
  margin: 0 18px;

  width: 2px;
  height: 30px;

  background-color: $text-color;
}

.user {
  &Information {
    display: flex;
    align-items: center;
  }

  &Icon {
    width: 22px;
    height: 22px;
    margin-right: 12px;
  }

  &Name {
    display: flex;
    align-items: center;

    color: $default-right-color;

    text-decoration: underline;

    cursor: pointer;
  }
}
