@import "styles/theme.scss";
.container {
  display: grid;
  place-items: center;

  height: 100%;
}

.title {
  font-size: $font-size-xxl;
  text-align: center;
}
