@import "styles/theme.scss";
.fragments {
  position: relative;
  width: 50px;
  display: grid;
  grid-template: repeat(2, 23px) / repeat(2, 23px);
  gap: 4px;

  &Letter {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-weight: $font-weight-bold;
  }
}
