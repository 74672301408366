@import "styles/theme.scss";
.content {
  display: flex;


  height: 100%;
}

.main {
  width: 100%;
  padding: 12px;

  overflow-y: auto;
}
